





























import { defineComponent, PropType, ref } from '@vue/composition-api'
import { Showroom } from '@/inc/types'
import { push, GtmLayer } from '@/inc/utils'

import UiPicture from '@/components/ui/Picture.vue'
import UiPill from '@/components/ui/Pill.vue'
import UiLoader from '@/components/ui/Loader.vue'

export default defineComponent({
  name: 'ShowroomCard',
  props: {
    content: {
      type: Object as PropType<Showroom>,
      required: true,
    },
  },
  components: { UiPicture, UiPill, UiLoader },
  setup(_props, ctx) {
    const rootEl = ref<HTMLElement | null>(null)

    const onClick = (showroom: Showroom) => {
      rootEl.value?.classList.add('is-loading')
      ctx.emit('click', rootEl.value, showroom)

      const layer: GtmLayer = {
        event: 'click_showroom',
        clickType: 'card',
        showroomName: showroom.value,
      }
      push(layer)
    }

    return { rootEl, onClick }
  },
})
