












































































import type { Showroom } from '@/inc/types'

import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { getApiUrl } from '@/inc/app.config'
import { cache } from '@/inc/utils'

import ContactMap from '@/components/contact/Map.vue'
import FlexibleTestimonials from '@/components/flexible/Testimonials.vue'
import ShowroomCard from '@/components/Showroom/Card.vue'
import ShowroomInfo from '@/components/Showroom/Info.vue'
import UiPill from '@/components/ui/Pill.vue'

export default defineComponent({
  name: 'Showrooms',
  components: {
    ContactMap,
    FlexibleTestimonials,
    ShowroomCard,
    ShowroomInfo,
    UiPill,
  },
  setup(_props, ctx) {
    const { content, chrome } = useGetters(['content', 'chrome'])
    const status = ref<'nearest' | 'all'>('all')
    const showrooms = ref<Showroom[]>(content.value.showrooms.items)
    const popupContent = ref<Showroom | Record<string, unknown> | null>(null)
    const contactUrl = chrome?.value?.ctas?.headerRdv?.url

    const openPopup = (showroom: Showroom) => {
      popupContent.value = showroom

      console.log('[Showrooms] openPopup', popupContent.value)
    }

    const closePopup = () => {
      popupContent.value = null
    }

    const onShowroomsAutocomplete = (showroomsByDistance: Showroom[]) => {
      status.value = 'nearest'
      showrooms.value = showroomsByDistance
    }

    const onCardClick = async (target: HTMLElement, showroom: Showroom) => {
      // TEMP:
      const urlParts = showroom.url.split('/')
      const slug = urlParts[urlParts.length - 2]

      // Fetch showroom content
      const url = `${getApiUrl()}/showrooms/${slug}`

      try {
        const res = await cache.fetch(ctx.root.$route.fullPath, url)

        // Open popup
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        popupContent.value = res.data.content
        target.classList.remove('is-loading')
      } catch (e) {
        console.error(e)
      }
    }

    return {
      content,
      status,
      showrooms,
      popupContent,
      contactUrl,
      openPopup,
      closePopup,
      onShowroomsAutocomplete,
      onCardClick,
    }
  },
})
